import { useMemo, useState } from 'react';
import {
  Button,
  CircularProgress,
  Container,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { showToastMsg } from '../features/toast-message-slice';
import { useGetPatientCareGapsQuery, useUpdateCareGapsMutation } from '../api/api';
import { CareGap } from '../types/CareGap';

const RELOAD_CARE_GAPS_INTERVAL = 1000 * 60; // 1 minute

const actions = ['document', 'refer', 'refuse', 'error'];
const actionColors = [
  '!bg-success-main/40 !text-black',
  '!bg-mediumPurple/40 !text-black',
  '!bg-error-main/40 !text-black',
  '!bg-error-main/40 !text-black',
];

const capitalize = (str: string) => (str ? `${str.slice(0, 1).toUpperCase()}${str.slice(1)}` : '');

type Props = {
  patientId?: string;
};

const CareGaps = ({ patientId = '' }: Props): JSX.Element => {
  const dispatch = useDispatch();

  const {
    data: careGaps = [],
    error: dataError,
    refetch,
    isLoading,
  } = useGetPatientCareGapsQuery(patientId, {
    pollingInterval: RELOAD_CARE_GAPS_INTERVAL,
    skipPollingIfUnfocused: true,
  });

  const [updateStatus, { isLoading: updateLoading }] = useUpdateCareGapsMutation();

  const [openDialog, setOpenDialog] = useState(false);
  const [openUndoDialog, setOpenUndoDialog] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedCareGapId, setSelectedCareGapId] = useState<string | null>(null);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<string | undefined>();
  const [note, setNote] = useState('');

  const handleOpenDialog = (careGapId: string, organizationId: string, status: string) => {
    setSelectedStatus(status);
    setSelectedCareGapId(careGapId);
    setSelectedOrganizationId(organizationId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    if (!updateLoading) {
      setOpenDialog(false);
      setSelectedStatus('');
      setSelectedCareGapId(null);
      setSelectedOrganizationId(undefined);
      setNote('');
    }
  };

  const handleOpenUndoDialog = (careGapId: string, organizationId: string | undefined, status: string) => {
    setSelectedCareGapId(careGapId);
    setSelectedOrganizationId(organizationId);
    setSelectedStatus(status);
    setOpenUndoDialog(true);
  };

  const handleCloseUndoDialog = () => {
    setOpenUndoDialog(false);
    setSelectedCareGapId(null);
    setSelectedStatus('');
    setNote('');
  };

  const handleStatusChange = async (
    careGapId: string | null,
    organizationId: string | undefined,
    status: string,
    note: string = ''
  ) => {
    if (!careGapId || !organizationId) return;

    try {
      await updateStatus({
        patientId,
        organizationId,
        careGapId,
        status,
        note,
      }).unwrap();
      refetch();
      handleCloseDialog();
      handleCloseUndoDialog();
    } catch (error: any) {
      const errorMessage = error?.data?.errorMessage || (error?.data as any)?.message || 'Update failed';
      dispatch(
        showToastMsg({
          open: true,
          message: errorMessage,
          level: 'error',
          duration: 5000,
        })
      );
    }
  };

  const handleUndoStatusChange = () => {
    handleStatusChange(selectedCareGapId, selectedOrganizationId, 'open', note);
  };

  const careGapsData: CareGap[] = useMemo(() => (Array.isArray(careGaps) ? careGaps : []), [careGaps]);

  if (isLoading) {
    return (
      <div className="flex justify-center p-3 min-h-[300px] my-[80px] w-100">
        <CircularProgress />
      </div>
    );
  }

  if (dataError) {
    let errorMessage = 'An error occurred';
    if ('status' in dataError) {
      const dataErrorObj = dataError as { status: number; data: { message?: string } };
      errorMessage = dataErrorObj.data?.message || 'An error occurred';
    } else {
      errorMessage = (dataError as any)?.message || 'An error occurred';
    }

    return (
      <div className="flex justify-center p-3 w-100 min-h-[300px] my-[80px]">
        <Typography variant="body1" className="text-error-dark">
          Error: {errorMessage}
        </Typography>
      </div>
    );
  }

  if (!careGapsData || careGapsData.length === 0) {
    return (
      <div className="flex justify-center p-3 w-100 min-h-[300px]">
        <Typography variant="body1">No Care gaps available</Typography>
      </div>
    );
  }

  return (
    <Container maxWidth={false} className="overflow-x-hidden">
      {/* Navigation Accordion */}

      <div className="grid my-[80px] gap-y-2">
        {careGapsData.map((careGap: CareGap) => (
          <Paper key={careGap._id} className="grid p-3 gap-y-2">
            <div className="grid justify-between grid-flow-col">
              <Typography variant="h2">{careGap.measureOnCompendium}</Typography>
              {careGap.lastLoadedDt && (
                <Typography variant="h3">
                  Last loaded: {format(new Date(careGap.lastLoadedDt), 'yyyy-MM-dd')}
                </Typography>
              )}
            </div>
            <Typography variant="body1">{careGap.messageOnCompendium}</Typography>
            {careGap.status === 'open' ? (
              <div className="grid grid-flow-col gap-x-2">
                {actions.map((action, i) => (
                  <Button
                    className={actionColors[i]}
                    variant="contained"
                    key={action}
                    onClick={() => handleOpenDialog(careGap._id!, careGap.organizationId!, action)}
                  >
                    {capitalize(action)}
                  </Button>
                ))}
              </div>
            ) : (
              <>
                {careGap.user && (
                  <Typography variant="body1">
                    Marked as <strong>{capitalize(careGap.status)}</strong> by{' '}
                    <strong>{`${careGap.user.firstName} ${careGap.user.lastName}`}</strong> on{' '}
                    {careGap.updatedDt
                      ? format(new Date(careGap.updatedDt), 'yyyy-MM-dd')
                      : format(new Date(), 'yyyy-MM-dd')}
                  </Typography>
                )}
                {careGap.note && (
                  <Typography variant="body1">
                    <strong>Note: </strong>
                    {careGap.note}
                  </Typography>
                )}
                <Button
                  color="error"
                  className="justify-self-end"
                  onClick={() => handleOpenUndoDialog(careGap._id!, careGap.organizationId, careGap.status!)}
                >
                  Undo {capitalize(careGap.status)}
                </Button>
              </>
            )}
          </Paper>
        ))}
      </div>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{capitalize(selectedStatus)} Note</DialogTitle>
        <DialogContent>
          <TextField
            label="Note (optional)"
            fullWidth
            multiline
            minRows={3}
            margin="normal"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} disabled={updateLoading}>
            Cancel
          </Button>
          <Button
            startIcon={updateLoading && <CircularProgress size={12} />}
            onClick={() => handleStatusChange(selectedCareGapId, selectedOrganizationId, selectedStatus, note)}
            variant="contained"
            disabled={updateLoading || !selectedCareGapId || !selectedOrganizationId}
          >
            {capitalize(selectedStatus)}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openUndoDialog} onClose={handleCloseUndoDialog}>
        <DialogTitle>Undo Care Gap: {capitalize(selectedStatus)}</DialogTitle>
        <DialogContent>
          <TextField
            label="Reason (required)"
            fullWidth
            multiline
            minRows={3}
            margin="normal"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUndoDialog} disabled={updateLoading}>
            Cancel
          </Button>
          <Button
            startIcon={updateLoading && <CircularProgress size={12} />}
            onClick={handleUndoStatusChange}
            variant="contained"
            color="error"
            disabled={updateLoading || !note}
          >
            Undo {capitalize(selectedStatus)}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CareGaps;
