import { useParams } from 'react-router-dom';
import PatientHCCs from './hcc/PatientHCCs';
import Container from '@mui/material/Container';
import { HccParams } from '../types/Hcc';
import useGetAuthValues from '../hooks/useGetAuthValues';

export default function CompendiumPage(): JSX.Element {
  const { patientId = '' } = useParams<HccParams>();
  const { user_email, access_token } = useGetAuthValues();

  return (
    <Container maxWidth={false} className="mt-0">
      {user_email && access_token && <PatientHCCs patientId={patientId} />}
    </Container>
  );
}
