import React from 'react';
import { Outlet } from 'react-router-dom'; // Outlet renders the matching child route
import Header from './components/Header'; // Import the Header component
import useGetAuthValues from './hooks/useGetAuthValues';

const AppLayout: React.FC = () => {
  const { user_email, access_token } = useGetAuthValues();
  return (
    <div>
      {user_email && access_token && <Header />}
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default AppLayout;
