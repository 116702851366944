import React from 'react';
import { useNavigate, useLocation, useParams, useSearchParams } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useMemo } from 'react';
import { useGetPatientCareGapsQuery, useGetPatientHccsQuery } from '../api/api';

type Props = {};

const RELOAD_INTERVAL = 1000 * 60; // 1 minute

const Header: React.FC<Props> = () => {
  const { patientId = '' } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [queryParams] = useSearchParams();

  const { data: hccSummary } = useGetPatientHccsQuery(patientId, {
    pollingInterval: RELOAD_INTERVAL,
    skipPollingIfUnfocused: true,
    selectFromResult: ({ data, error, isLoading }) => ({
      data,
      isLoading,
    }),
  });

  const { data: careGaps = [] } = useGetPatientCareGapsQuery(patientId, {
    pollingInterval: RELOAD_INTERVAL,
    skipPollingIfUnfocused: true,
  });

  const newHccCount = useMemo(() => {
    return hccSummary?.codes?.['Newly Identified ICD-10 Possibilities']
      ? Object.values(hccSummary.codes['Newly Identified ICD-10 Possibilities']).reduce(
          (total, category) => total + category.length,
          0
        )
      : 0;
  }, [hccSummary]);

  const careGapCount = useMemo(() => careGaps.filter((careGap) => careGap.status === 'open').length, [careGaps]);

  const userEmail = queryParams.get('user_email');
  const accessToken = queryParams.get('access_token');

  const queryString = `?user_email=${userEmail || ''}&access_token=${accessToken || ''}`;

  const isCareGapsPage = location.pathname.includes(`${patientId}/care-gaps`);
  const isHccSummaryPage = location.pathname.includes(`${patientId}`);

  const handleNavigation = () => {
    if (isCareGapsPage) {
      navigate(`/patient/${patientId}${queryString}`);
    } else if (isHccSummaryPage) {
      navigate(`/patient/${patientId}/care-gaps${queryString}`);
    }
  };

  return (
    <AppBar className="!absolute !bg-white" onClick={handleNavigation} style={{ cursor: 'pointer' }}>
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <div className="flex justify-between w-full">
            <div className="flex items-center gap-x-2 ">
              <img className="h-[45px]" height={45} src="/logo-mobile.svg" alt="Reveleer Logo" />
              <Typography variant="h2" className="!text-black !m-0">
                {isCareGapsPage ? 'Care Gaps' : 'HCC Summary'}
              </Typography>
            </div>
            <Button className="!text-black !text-lg !font-medium hover:!bg-gray-300">
              {isCareGapsPage ? (
                <>HCC Summary {newHccCount > 0 && <span>({newHccCount})</span>}</>
              ) : (
                <>Care Gaps {careGapCount > 0 && <span>({careGapCount} open)</span>}</>
              )}
              : Click here to review
            </Button>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
