import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { RootState } from '../app/store';
import { setUserParams } from '../features/user-params-slice';

const useGetAuthValues = () => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const user_email_params = useMemo(() => searchParams.get('user_email'), [searchParams]);
  const access_token_params = useMemo(() => searchParams.get('access_token'), [searchParams]);

  const { user_email, access_token } = useSelector((state: RootState) => state.userParams);

  useEffect(() => {
    if (user_email_params && access_token_params) {
      dispatch(
        setUserParams({
          user_email: user_email_params,
          access_token: access_token_params,
        })
      );
    }
  }, [user_email_params, access_token_params, dispatch]);

  return { user_email, access_token };
};

export default useGetAuthValues;
