// @flow

import React, { useMemo } from 'react';
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import HccSourceCard from './HccSourceCard';
import ActionModal from './ActionModal';
import InternalNoteModal from './InternalNoteModal';
import HipaaLogModal from './HipaaLogModal';
import PdfModal from './PdfModal';
import { useGetPatientHccsQuery } from '../../api/api';
import { format } from 'date-fns';

export const WORKFLOW_ACTION_SLUG_SUFFIXES = {
  QUERY_PROVIDER: 'query_provider',
  DENY: 'deny',
  INTERNAL_NOTE: 'internal_note',
  CONFIRM: 'confirm',
  BACK_TO_CODER: 'back_to_coder',
};
export const getButtonClass = (slug: string, classes: { [key: string]: string }) => {
  switch (true) {
    case slug.endsWith(WORKFLOW_ACTION_SLUG_SUFFIXES.QUERY_PROVIDER):
      return classes.query_provider;
    case slug.endsWith(WORKFLOW_ACTION_SLUG_SUFFIXES.DENY):
      return classes.deny;
    case slug.endsWith(WORKFLOW_ACTION_SLUG_SUFFIXES.INTERNAL_NOTE):
      return classes.addInternalNote;
    case slug.endsWith(WORKFLOW_ACTION_SLUG_SUFFIXES.CONFIRM):
      return classes.confirm;
    case slug.endsWith(WORKFLOW_ACTION_SLUG_SUFFIXES.BACK_TO_CODER):
      return classes.back_to_coder;
  }

  return '';
};

type Props = {
  patientId?: string;
};

const RELOAD_PATIENT_HCCS_INTERVAL = 1000 * 60; // 1 minute

const PatientHCCs = (props: Props): JSX.Element => {
  const { patientId = '' } = props;

  const { data, isLoading: patientHccLoading } = useGetPatientHccsQuery(patientId, {
    pollingInterval: RELOAD_PATIENT_HCCS_INTERVAL,
    skipPollingIfUnfocused: true,
    selectFromResult: ({ data, error, isLoading }) => ({
      data,
      isLoading,
    }),
  });
  const patientHccs = useMemo(() => data?.codes, [data]);
  const pickOpenEncounterToAcceptICD = useMemo(() => data?.pickOpenEncounterToAcceptICD, [data]);
  const encounters = useMemo(() => data?.encounters, [data]);
  const hccStats = useMemo(
    () => ({
      currentCodesCount: data?.currentCodesCount,
      currentSourcesCount: data?.currentSourcesCount,
      totalCodesCount: data?.totalCodesCount,
      totalSourcesCount: data?.totalSourcesCount,
      lastUpdated: data?.compendiumLastUpdated,
    }),
    [data]
  );

  useEffect(() => {
    console.log('[PatientHccs] useEffect....');
  }, []);

  const Main = () => (
    <>
      {patientHccLoading ? (
        <div className="flex items-start justify-center w-full mt-[80px]">
          <CircularProgress />
        </div>
      ) : patientHccs ? (
        <>
          {/* <div style={{ marginBottom: 40 }}>
            <Button onClick={exportCodesToCSV} disabled={loadingCSV} variant="outlined">
              {loadingCSV ? 'Exporting...' : 'Export to CSV'}
            </Button>
          </div> */}
          <div className="mt-[80px]">
            <div>
              {/* <HCCFilter
            setUrlQueryParams={setUrlQueryParams}
            loading={loadingPatientData}
          /> */}
            </div>
            <div className="flex flex-col items-start">
              <div className="patient-demographics">
                {data?.patient && (
                  <>
                    {data?.patient.firstName && data?.patient.lastName && (
                      <Typography variant="h1" className="!mt-3 !font-semibold">
                        {data?.patient.firstName} {data?.patient.lastName}
                      </Typography>
                    )}
                    {/* <div>
                  <Typography>
                    Date of Birth: <strong>{patientData.dob}</strong>
                  </Typography>
                  <Typography>
                    Patient ID: <strong>{patientData.id}</strong>
                  </Typography>
                </div> */}
                  </>
                )}
              </div>
              <div className="my-2 text-left">
                {hccStats.lastUpdated ? (
                  <>
                    <span>{format(new Date(hccStats.lastUpdated), 'MMM dd yyyy p (z)')}</span>
                    <br />
                    <span className="text-[0.8rem]">Compendium last updated</span>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div>
              {Object.keys(patientHccs).map((section) => (
                <div key={section} className="mb-5">
                  <div className="flex justify-between">
                    {/* eslint-disable-next-line max-len */}
                    <Typography className="uppercase !font-bold !text-[2rem] text-mineShaft/60 font-dmSans leading-13 tracking-[-0.03em]">
                      {section}
                    </Typography>
                  </div>
                  <div className="mt-3">
                    {patientHccs[section] ? (
                      Object.keys(patientHccs[section]).length ? (
                        <>
                          {Object.keys(patientHccs[section]).map((key) => (
                            <HccSourceCard
                              key={key}
                              _key={`${section}-${key}`}
                              hccLabel={key}
                              patientHccs={patientHccs[section][key]}
                              pickOpenEncounterToAcceptICD={pickOpenEncounterToAcceptICD}
                              encounters={encounters}
                            />
                          ))}
                        </>
                      ) : (
                        <span style={{ fontSize: '1rem' }}>No results found</span>
                      )
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <span style={{ fontSize: 14 }}>No results found</span>
      )}
    </>
  );

  return (
    <>
      <ActionModal />
      <InternalNoteModal />
      <HipaaLogModal />
      <PdfModal />
      <Main />
    </>
  );
};

export default PatientHCCs;
