// api/api.ts
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IcdPayload } from '.././components/hcc/ActionModal';
import { FileData, Hcc, IcdSourcesAndNotes } from '../types/Hcc';
import { RootState } from '../app/store';
import { CareGap } from '../types/CareGap';

const serverURL = process.env.REACT_APP_API_ENDPOINT;

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${serverURL}/embed`,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      headers.set('Authorization', `Bearer ${state.userParams.access_token}`);
      headers.set('user-email', state.userParams.user_email);

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPatientHccs: builder.query<Hcc, string>({
      query: (patientId) => `/patient/${patientId}/hcc`,
      transformResponse: (response: any) => {
        return response.data;
      },
    }),
    getIcdSourcesAndNotes: builder.query<IcdSourcesAndNotes, { patientId: string | undefined; icdCodeId: string }>({
      query: ({ patientId, icdCodeId }) => `/patient/${patientId}/icd/${icdCodeId}`,
    }),
    getChangelogCompendiumsByHccId: builder.query({
      query: (hccId) => `changelogCompendium/${hccId}`,
      transformResponse: (response: any) => {
        return response.data;
      },
    }),
    addInternalNote: builder.mutation<object, { hccId: string; notes: string }>({
      query: (data) => ({
        url: `/hcc/${data.hccId}/note`,
        method: 'POST',
        body: {
          notes: data.notes,
        },
      }),
      transformResponse: (response: any) => {
        return response.success;
      },
    }),
    setIcdCode: builder.mutation<object, IcdPayload>({
      query: (body) => ({ url: '/hcc', method: 'PUT', body }),
      transformResponse: (response: any) => {
        return response.success;
      },
    }),
    // Missing RETURN type. OBJ by now
    getHccSourceDocument: builder.query<FileData, { patientId: string; fileHash: string }>({
      query: ({ patientId, fileHash }) => `/patient/${patientId}/hcc/source/${fileHash}`,
    }),
    // Missing RETURN type. OBJ by now
    // http://localhost:2999/embed/patient/050GJ949/ecda?f=06bedf952a5f41653545ee54680965c2&title=Barbara_Koch_progress_note.xml
    getLatestPatientEcda: builder.query<object, { patientId: string; f: string; title: string }>({
      query: ({ patientId, f, title }) => `/patient/${patientId}/ecda?f=${f}&title=${title}`,
      transformResponse: (response: any) => {
        return response.data;
      },
    }),
    deleteIcdNote: builder.mutation<object, { noteId: string }>({
      query: ({ noteId }) => ({
        url: `/patient/icd/delete-note/${noteId}`,
        method: 'POST',
      }),
      transformResponse: (response: any) => {
        return response.success;
      },
    }),
    // Missing RETURN type. OBJ by now
    getUser: builder.query<object, void>({
      query: () => '/user',
      transformResponse: (response: any) => {
        return response.data;
      },
    }),
    // New endpoint to get patient care gaps
    getPatientCareGaps: builder.query<CareGap[], string>({
      query: (patientId) => `/patient/${patientId}/care-gaps`,
      transformResponse: (response: any) => {
        return response.data;
      },
    }),
    // New endpoint to update care gaps
    updateCareGaps: builder.mutation<
      object,
      { patientId: string; organizationId: string; careGapId: string; status: string | null; note: string }
    >({
      query: ({ patientId, organizationId, careGapId, status, note }) => ({
        url: `patient/${patientId}/care-gaps/update`,
        method: 'POST',
        body: {
          organizationId,
          careGapId,
          status,
          note,
        },
      }),
      transformResponse: (response: any) => {
        return response.data;
      },
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetPatientHccsQuery,
  useLazyGetPatientHccsQuery,
  useGetIcdSourcesAndNotesQuery,
  useLazyGetChangelogCompendiumsByHccIdQuery,
  useGetChangelogCompendiumsByHccIdQuery,
  useAddInternalNoteMutation,
  useSetIcdCodeMutation,
  useLazyGetHccSourceDocumentQuery,
  useDeleteIcdNoteMutation,
  useGetLatestPatientEcdaQuery,
  useGetUserQuery,
  useLazyGetIcdSourcesAndNotesQuery,
  useGetPatientCareGapsQuery,
  useUpdateCareGapsMutation,
} = apiSlice;
